import type { NextPage } from "next";
import Image from "next/image";
import Link from "next/link";
import AuthLogin from "../components/authLogin";
import { useAppSelector } from "../lib/client/store/hooks";
import { menuAdmin } from "../lib/client/tools";
import { checkPerm } from "../lib/mixedAll";
import TwoSellSmall from "../public/imgs/2sell-small.png";

const Home: NextPage<{ is_testing: boolean }> = ({ is_testing }) => {
  const user = useAppSelector((state) => state.user);

  let menu: Record<string, string> = {
    "quote-corporate": "Quotes",
    addons: "Add-Ons",
    "prod-revert": "Sheet Client",
    "req-client-data": "Dati nuovi clienti",
    editor: "Editor",
    reseller: "Reseller",
  };
  let menuAdminShow: Record<string, string> = {};

  if (checkPerm(user).isAdmin || checkPerm(user).isManager)
    menu = {
      ...menu,
      setup: "Config.",
    };
  if (checkPerm(user).isAdmin) menuAdminShow = menuAdmin;

  return (
    <AuthLogin>
      <div className="container mx-auto h-full flex">
        <div className="grid grid-cols-1 w-full">
          <div className="card w-full lg:w-3/5 bg-neutral text-white shadow-xl place-self-center">
            <div className="card-body">
              <div className="flex justify-around flex-wrap">
                <Image
                  priority
                  src={TwoSellSmall}
                  alt="logo"
                  className="w-32"
                />
              </div>
              <p>
                Welcome <i>{user.name}</i>
              </p>
              <div className="grid grid-cols-3 gap-4">
                {Object.entries(menu).map(([url, label]) => (
                  <Link
                    key={url}
                    href={url}
                    className={`btn btn-lg ${
                      checkPerm(user).isAdmin ? "btn-success" : "btn-primary"
                    }`}
                  >
                    {label}
                  </Link>
                ))}
              </div>
              <div className="grid grid-cols-3 gap-4 mt-8">
                {checkPerm(user).isAdmin &&
                  Object.entries(menuAdminShow).map(([url, label]) => (
                    <Link
                      key={url}
                      href={url}
                      className="btn btn-lg btn-primary"
                    >
                      {label}
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLogin>
  );
};

export default Home;
